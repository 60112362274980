<template>
  <div class="d-flex">
    <b-badge
      v-if="params.publication_status === 'draft'"
      pill
      class="h-6"
      variant="warning"
    >
      Draft
    </b-badge>
    <b-badge
      v-else-if="params.publication_status === 'pending'"
      pill
      class="h-6"
      variant="info"
    >
      Pending
    </b-badge>
    <b-badge
      v-else-if="['published', 'live'].includes(params.publication_status)"
      pill
      class="h-6"
      variant="success"
    >
      Published
    </b-badge>
    <b-badge
      v-else-if="params.publication_status === 'rejected'"
      pill
      class="h-6"
      variant="danger"
    >
      Rejected
    </b-badge>

  </div>

</template>

<script>
export default {
  name: 'CellRendererCategory',
  props: {
    params: Object,
  },
  methods: {
    // eslint-disable-next-line consistent-return
    humanizedCategory(table) {
      if (table === 'single') {
        return 'Single Event'
      } if (table === 'events') {
        return 'Group Event'
      } if (table === 'events_jobs') {
        return 'Participating Event'
      }
    },
  },
}
</script>
