<template>
  <div
    id="div-with-loading"
    class="vs-con-loading__container mx-12 mt-8"
  >

    <stripe-checkout
      ref="checkoutRef"
      mode="subscription"
      :pk="publishableKey"
      :customer-email="customer_email"
      :line-items="corporateItems"
      :success-url="successURL"
      :cancel-url="cancelURL"
      @loading="v => (loading = v)"
    />
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <sideview
        :is-sidebar-active="sidebarActivated"
        :data="billing_info"
        @closeSidebar="toggleDataSidebar"
      />

      <b-modal
        v-model="purchaseConfirmationPopup"
        centered
        hide-footer
        no-close-on-backdrop
        title="Purchase Confirmation"
      >
        <b-row>
          <h6 class="mx-auto p-1">
            To continue using our service, we kindly request that you subscribe to our
            <b>{{ purchase_info.default_product.name }}</b> package at a <b>{{ purchase_info.default_product.price }}</b>.

          </h6>
          <p class="mx-auto px-1">
            By subscribing to this package, {{ purchase_info.default_product.description }}
          </p>
        </b-row>

        <div v-if="purchase_info.default_product.name == 'Monitor Company Industry'">
          <b-row
            class=" mt-2"
          >
            <p class="px-1">
              Before we proceed, select the industries of your choice
            </p>

          </b-row>

          <b-row
            class=""
          >

            <v-select
              v-model="selected_monitored_industries"
              class="w-100 text-white px-1"
              multiple
              autocomplete
              placeholder="Start typing to get industry suggestions"
              :options="dynamicIndustryOptions.map(item => item.text)"
            />
          </b-row>

          <b-row
            class="d-flex flex-row-reverse w-100 mt-1 "
          >

            <p class="float-right">
              Total Amount: <strong>S${{ selected_monitored_industries.length * purchase_info.default_product.amount }}/month</strong>
            </p>
          </b-row>
        </div>

        <div v-if="purchase_info.default_product.name == 'Monitor Position'">

          <b-row
            class=" mt-2"
          >
            <p class="px-1">
              Before we proceed, select the position you would like to monitor
            </p>

          </b-row>
          <b-row
            class=""
          >
            <v-select
              v-model="selected_monitored_positions"
              class="w-100 text-white px-1"
              multiple
              autocomplete
              placeholder="Start typing to get position suggestions"
              :options="dynamicPositionOptions.map(item => item.text)"
            />
          </b-row>
          <b-row
            class="d-flex flex-row-reverse w-100 mt-1"
          >

            <p class="float-right">
              Total Amount: <strong>S${{ selected_monitored_positions.length * purchase_info.default_product.amount }}/month</strong>
            </p>
          </b-row>

        </div>

        <b-row class="mt-2">

          <!-- <b-button
            variant="primary"
            class="col mx-1 w-1/2"
            @click="addToCart"
          >
            <feather-icon
              icon="ShoppingCartIcon"
              size="18"
              class="mr-1"
            />
            Add to Cart
          </b-button> -->

          <b-button
            variant="warning"
            class="col mx-1 w-100"
            :disabled="
              purchase_info.default_product.name == 'Monitor Company Industry'
                ? $length(selected_monitored_industries) < 1
                : purchase_info.default_product.name == 'Monitor Position'
                  ? $length(selected_monitored_positions) < 1
                  : true
            "
            @click="subscribeNow"
          >
            <feather-icon
              icon="CreditCardIcon"
              size="18"
              class="mr-1"
            />
            {{ subText }}
          </b-button>
        </b-row>
      </b-modal>

      <b-modal
        v-model="promo_popup"
        centered
        hide-footer
        title="Promo Code"
      >
        <b-row>
          <h6 class="mx-auto">
            Enter your promo code below if you have one
          </h6>
        </b-row>
        <b-row>
          <b-form-input
            v-model="promo_code"
            class="sm:w-full mx-1"
            placeholder="PROMO CODE"
          />
        </b-row>

        <div class="flex w-full mx-auto my-1">
          <b-button
            variant="danger"
            class="col mx-1 w-1/2 mt-1"
            @click="skipPromo"
          >Skip</b-button>
          <b-button
            variant="warning"
            class="col mx-1 w-1/2 mt-1"
            @click="validatePromo"
          >Validate Promo Code</b-button>
        </div>
      </b-modal>

      <div
        v-if="$checkRights($route.meta.section, 'read')"
        class="row px-2 mt-1"
      >
        <b-card class="w-100 ">
          <div class="mb-1">
            <div class="d-flex flex-wrap">
              <b-col
                sm="12"
                lg="4"
              >
                <h6 class="mt-1">
                  Company Name
                </h6>
                <CompanySearch
                  id="company-name"
                  v-model="selected_company"
                  name="company-name"
                  placeholder="Start typing to get company name suggestions"
                  :multiple="false"
                  @company-selected="handleCompanyInput"
                />

              </b-col>

              <!-- <b-col
                sm="12"
                lg="4"
              >
                <h6 class="mt-1">
                  Position
                </h6>
                <v-select
                  v-model="selected_positions"
                  class="text-white"
                  multiple
                  autocomplete
                  placeholder="Start typing to get position suggestions"
                  :options="positionOptions"
                  label="text"
                  :reduce="(value) => value.value"
                />
              </b-col> -->

              <b-col
                sm="12"
                lg="4"
              >
                <h6 class="mt-1">
                  Industry
                </h6>
                <v-select
                  v-model="selected_industries"
                  class="text-white"
                  multiple
                  autocomplete
                  placeholder="Start typing to get industry suggestions"
                  :options="industryOptions"
                  label="text"
                  :reduce="(value) => value.value"
                />
              </b-col>

              <b-col
                sm="12"
                lg="4"
              >
                <h6 class="mt-1">
                  Recruitment Status
                </h6>
                <v-select
                  v-model="selected_status"
                  label="text"
                  class=""
                  autocomplete
                  placeholder="Select recruitment status"
                  :options="recruitment_statuses"
                />
              </b-col>
            </div>

            <div class="d-flex flex-wrap">
              <b-col
                sm="12"
                lg="4"
              >
                <b-button
                  class="mt-1 w-100"
                  variant="primary"
                  @click="toggleMonitoringList('companies')"
                >
                  Monitored Companies
                </b-button>
              </b-col>

              <b-col
                sm="12"
                lg="4"
              >
                <b-button
                  class="mt-1 w-100"
                  variant="primary"
                  @click="toggleMonitoringList('industries')"
                >
                  Monitored Industries
                </b-button>
              </b-col>

              <b-col
                sm="12"
                lg="4"
              >
                <b-button
                  class="mt-1 w-100"
                  variant="primary"
                  @click="toggleMonitoringList('positions')"
                >
                  Monitored Positions
                </b-button>
              </b-col>
            </div>
          </div>
        </b-card>
      </div>

      <b-row class="mx-auto px-0 px-md-2">
        <hr>
        <h4
          v-if="!$checkRights($route.meta.section, 'read') || (companiesData && companiesData.length < 1)"
          class="grey mx-auto text-center"
        >
          No data available for now
        </h4>
        <div class="d-flex flex-row flex-wrap justify-content-between mx-0 px-0 w-100">
          <!-- <div v-for="(group, i) in eventsGroups" :key="i"> -->
          <!-- .slice(i * 4, (i + 1) * 4) -->

          <div
            v-for="(item, index) in companiesData"
            :key="index"

            class="d-flex mx-0 px-0 p-0 p-md-1"
            :class="$isMobile() ? `w-100`: `w-50`"
          >
            <b-card class="d-flex flex-fill m-0 p-0">
              <div class="d-flex flex-column justify-content-around h-100">
                <div class="">
                  <div class="d-flex justify-content-between align-items-start">
                    <h5 class="font-weight-bolder">
                      {{ item.company_name }} ({{ item.company_uen_no }})
                    </h5>
                    <!-- <b-badge pill
                    :key="index"
                    v-for="(text, index) in item.preferences"
                    variant="warning"
                    class="h-4 mt-2 ml-4"
                    >{{ capitalizeFirstLetter(text) }}</b-badge
                  >-->
                    <div class="">
                      <!-- <feather-icon
                        v-if="!item.paid"
                        icon="ShoppingCartIcon"
                        class="cursor-pointer text-primary mr-1"
                        size="30"
                        :class="item.shopping_cart ? 'fill-primary-lighten-3' : ''"
                        @click="toggleCompanyShoppingCart(item, index)"
                      /> -->
                      <feather-icon
                        icon="BellIcon"
                        class="cursor-pointer text-primary"
                        size="30"
                        :class="{
                          'fill-primary-lighten-3': item.user_active_monitor_state,
                          'disabled': item.user_active_monitor_state
                        }"
                        @click="toggleActiveMonitoring(item, index)"
                      />
                    </div>
                  </div>

                  <div class="row mt-1 self-start">
                    <div class="d-flex">
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="cursor-pointer ml-1"
                      />
                      <h6 class="">
                        Primary SSIC: {{ item.primary_ssic }}
                        {{ checkDescription(item.primary_ssic_description) }}
                      </h6>
                    </div>
                  </div>

                  <div class="row mt-1 self-start">
                    <div class="d-flex">
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="cursor-pointer ml-1"
                      />
                      <h6 class="">
                        Secondary SSIC: {{ item.secondary_ssic }}
                        {{ checkDescription(item.secondary_ssic_description) }}
                      </h6>
                    </div>
                  </div>

                  <div class="row mt-1 self-start">
                    <div class="d-flex">
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="cursor-pointer ml-1"
                      />

                      <h6 class="">
                        Recruitment Status: We believe that it's
                        <span
                          v-if="item.recruitment_status == 'active'"
                          class="text-success"
                        >
                          {{ item.recruitment_status }}
                        </span>
                        <span
                          v-else
                          class="text-danger"
                        >
                          inactive
                        </span>
                      </h6>
                    </div>
                  </div>

                  <div
                    v-if="item.private"
                    class="row mt-1 self-start"
                  >
                    <!-- <feather-icon
                    icon="LayersIcon"
                    class="cursor-pointer"
                  ></feather-icon> -->
                    <!-- <h5 class="ml-2 mt-1 text-primary">
                    {{ item.company.toUpperCase() }}
                  </h5> -->
                    <feather-icon
                      icon="ChevronRightIcon"
                      class="cursor-pointer ml-1"
                    />
                    <h6 class="ml-2 mt-1">
                      Active since {{ item.licence_date }}
                    </h6>
                  </div>
                </div>
                <hr>

                <div
                  class="d-flex justify-content-between align-items-end mt-2 w-full self-center"
                >
                  <div class="self-center text-bottom">
                    <h6
                      class="m-0 p-0"
                      style="font-weight: bold; font-style: italic"
                    >
                      Update as of {{ new Date().toLocaleDateString() }}
                    </h6>
                  </div>

                  <div>
                    <b-button
                      variant="primary"
                      @click="checkSubscription(item)"
                    >
                      View More
                    </b-button>
                  </div>

                  <div v-if="false">
                    <b-button
                      to="/corporate-directory-details"
                      class="mr-2 ml-2"
                      variant="outline-primary"
                    >Email Address</b-button>
                    <b-button
                      to="/corporate-directory-details"
                      class="mr-2 ml-2"
                      variant="outline-primary"
                    >Phone Number</b-button>
                    <b-button
                      to="/corporate-directory-details"
                      class="mr-2 ml-2"
                      variant="outline-primary"
                    >View More Info</b-button>
                  </div>
                </div>
              </div>
            </b-card>
          </div>

          <div class="mt-2 d-flex align-items-center justify-content-center w-100">
            <b-pagination
              :value="serverParams.page"
              :total-rows="serverParams.totalRows"
              :per-page="serverParams.perPage"
              first-number
              last-number
              align="center"
              prev-class="prev-item"
              next-class="next-item"
              class=""
              @input="(value) => onPageChange({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-row>

      <div
        v-if="$checkRights(this.$route.meta.section, 'read')"
        class="row mx-auto"
      >
        <b-button
          v-if="companiesData.length >= default_count"
          class="shadow-lg mx-auto"
          variant="warning"
          @click="viewMore"
        >View more companies</b-button>
      </div>

      <hr v-if="$checkRights(this.$route.meta.section, 'read')">

      <b-card
        v-if="popup_payment"
        class="container my-4"
      >
        <div class="row w-full my-4">
          <h6 class="mx-auto text-center">
            To view the corporate directory you need to have an active
            subscription.
          </h6>
        </div>

        <b-button
          variant="warning"
          class="row mx-auto mt-4"
          @click="subscribe('corporate_directory')"
        >Subscribe now for S$20 per Month</b-button>
      </b-card>
    </b-overlay>

    <b-modal
      v-model="popup_corporate_details.state"
      centered
      hide-footer
      size="xl"
      :title="`Corporate Details - ${popup_corporate_details.data.company_name} (${popup_corporate_details.data.company_uen_no})`"
    >
      <CorporateDirectoryViewMore
        :data="popup_corporate_details.data"
      />
    </b-modal>

    <b-modal
      v-model="popup_active_monitoring_list.state"
      centered
      hide-footer
      size="lg"
      title="Companies in your watchlist"
    >
      <div class="">
        <div
          class="mt-1 d-flex flex-column align-items-center justify-content-center overflow-auto"
        >

          <div class="w-100">
            <b-row class="w-100">
              <CompanySearch
                id="company-name"
                v-model="selected_company"
                class="flex-fill px-1"
                placeholder="Start typing to get company name suggestions"
                :multiple="false"
                @company-selected="selectActiveMonitoringCompany"
              />
            </b-row>

            <b-row class="w-100 mb-1 mt-1">
              <b-button
                variant="primary"
                class=" w-100 mx-1 "
                @click="toggleActiveMonitoring(selected_active_monitoring_company, null)"
              >
                Add to List
              </b-button>
            </b-row>

          </div>

          <div
            v-if="$isNotEmpty(popup_active_monitoring_list.data)"
            class="w-100 overflox-auto"
          >
            <b-table
              hover
              :current-page="popup_active_monitoring_list.serverParams.currentPage"
              :per-page="popup_active_monitoring_list.serverParams.perPage"
              :total-rows="popup_active_monitoring_list.serverParams.totalRows"
              :items="popup_active_monitoring_list.data"
              :fields="popup_active_monitoring_list.fields"
              class="w-100"
            >
              <template #cell(actions)="{item}">
                <div class="d-flex flex-gap-md align-items-center">
                  <feather-icon
                    icon="BellIcon"
                    class="cursor-pointer text-primary fill-primary-lighten-3"
                    size="30"
                    @click="toggleActiveMonitoring(item)"
                  />

                  <b-button
                    variant="primary"
                    @click="checkSubscription(item)"
                  >
                    View More
                  </b-button>
                </div>
              </template>
            </b-table>

          </div>

          <div
            v-else
            class="py-3"
          >
            No company added to watchlist yet
          </div>
        </div>

        <b-pagination
          v-model="popup_active_monitoring_list.serverParams.currentPage"
          :per-page="popup_active_monitoring_list.serverParams.perPage"
          :total-rows="popup_active_monitoring_list.serverParams.totalRows"
          aria-controls="my-table"
          class="mt-2 float-right"
        />
      </div>
    </b-modal>

    <b-modal
      v-model="popup_active_positions_monitoring_list.state"
      centered
      hide-footer
      size="lg"
      title="Positions in your watchlist"
    >
      <div class="">
        <div
          class="mt-1 d-flex flex-column align-items-center justify-content-center overflow-auto"
        >

          <div class="w-100">
            <b-row class="w-100">
              <v-select
                v-model="selected_monitored_positions"
                class="text-white px-1 w-100"
                multiple
                autocomplete
                placeholder="Start typing to get position suggestions"
                :options="dynamicPositionOptions.map(item => item.text)"
              />
            </b-row>

            <b-row class="w-100">
              <b-button
                variant="primary"
                class="w-100 mx-1 mt-1"
                @click="toggleMonitoringList('positions', true)"
              >
                Add to List
              </b-button>
            </b-row>

          </div>

          <div
            v-if="$isNotEmpty(popup_active_positions_monitoring_list.data)"
            class="w-100 overflox-auto mt-2"
          >
            <b-table
              hover
              :current-page="popup_active_positions_monitoring_list.serverParams.currentPage"
              :per-page="popup_active_positions_monitoring_list.serverParams.perPage"
              :total-rows="popup_active_positions_monitoring_list.serverParams.totalRows"
              :items="popup_active_positions_monitoring_list.data"
              :fields="popup_active_positions_monitoring_list.fields"
              class="w-100"
            >
              <template #cell(actions)="{item}">
                <div class="d-flex flex-gap-md align-items-center">
                  <feather-icon
                    icon="BellIcon"
                    class="cursor-pointer text-primary fill-primary-lighten-3"
                    size="30"
                    @click="toggleActiveMonitoringPosition(item)"
                  />

                  <b-button
                    v-if="false"
                    variant="primary"
                    @click="checkSubscription(item)"
                  >
                    View More
                  </b-button>
                </div>
              </template>
            </b-table>

          </div>

          <div
            v-else
            class="py-3 mt-2 w-100 d-flex justify-content-center"
          >
            No positions added to watchlist yet
          </div>
        </div>

        <b-pagination
          v-model="popup_active_positions_monitoring_list.serverParams.currentPage"
          :per-page="popup_active_positions_monitoring_list.serverParams.perPage"
          :total-rows="popup_active_positions_monitoring_list.serverParams.totalRows"
          aria-controls="my-table"
          class="mt-2 float-right"
        />
      </div>
    </b-modal>

    <b-modal
      v-model="popup_active_industries_monitoring_list.state"
      centered
      hide-footer
      size="lg"
      title="Industries in your watchlist"
    >
      <div
        class=""
      >
        <div
          class="mt-1 d-flex flex-column align-items-center justify-content-center overflow-auto"
        >
          <div class="w-100">
            <b-row class="w-100">
              <v-select
                v-model="selected_monitored_industries"
                class="text-white px-1 w-100"
                multiple
                autocomplete
                placeholder="Start typing to get industry suggestions"
                :options="dynamicIndustryOptions.map(item => item.text)"
              />
            </b-row>

            <b-row class="w-100">
              <b-button
                class="w-100 mx-1 mt-1"
                variant="primary"
                @click="toggleMonitoringList('industries', true)"
              >
                Add to List
              </b-button>
            </b-row>

          </div>

          <div
            v-if="$isNotEmpty(popup_active_industries_monitoring_list.data)"
            class="w-100 overflox-auto"
            style="height: 400px;"
          >
            <b-table
              hover
              :current-page="popup_active_industries_monitoring_list.serverParams.currentPage"
              :per-page="popup_active_industries_monitoring_list.serverParams.perPage"
              :total-rows="popup_active_industries_monitoring_list.serverParams.totalRows"
              :items="popup_active_industries_monitoring_list.data"
              :fields="popup_active_industries_monitoring_list.fields"
              class="w-100 mt-2"
            >
              <template #cell(actions)="{item}">
                <div class="d-flex flex-gap-md align-items-center">
                  <feather-icon
                    icon="BellIcon"
                    class="cursor-pointer text-primary fill-primary-lighten-3"
                    size="30"
                    @click="toggleActiveMonitoringIndustry(item)"
                  />

                  <b-button
                    v-if="false"
                    variant="primary"
                    @click="checkSubscription(item)"
                  >
                    View More
                  </b-button>
                </div>
              </template>
            </b-table>

          </div>

          <div
            v-else
            class="py-3 mt-2 w-100 d-flex justify-content-center"
          >
            No industries added to watchlist yet
          </div>
        </div>

        <b-pagination
          v-model="popup_active_industries_monitoring_list.serverParams.currentPage"
          :per-page="popup_active_industries_monitoring_list.serverParams.perPage"
          :total-rows="popup_active_industries_monitoring_list.serverParams.totalRows"
          aria-controls="my-table"
          class="mt-2 float-right"
        />
      </div>
    </b-modal>

  </div>
</template>

<script>
import { format } from 'date-fns'
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BOverlay,
  BFormCheckbox,
  BAlert,
  BTab,
  BTabs,
} from 'bootstrap-vue'
import { debounce } from 'lodash'
import CompanySearch from '@/components/CompanySearch.vue'
import { EventBus } from '@/signals/EventBus'
import Sideview from '../payments/Sideview.vue'
import CorporateDirectoryViewMore from './CorporateDirectoryViewMore.vue'

export default {
  components: {
    StripeCheckout,
    Sideview,
    vSelect,
    BCard,
    BTab,
    BTabs,
    BButton,
    BRow,
    BCol,
    BOverlay,
    BFormCheckbox,
    BAlert,
    CorporateDirectoryViewMore,
    CompanySearch,
  },
  data() {
    return {
      subText: 'Subscribe Now',
      checkoutUrl: null,
      selected_products: [],
      purchaseConfirmationPopup: false,
      purchase_info: {
        default_product: {
          name: 'Product Name',
          description: 'Product Description',
          price: 'X amount', // Assuming this is where the price will be displayed
        },
      },

      purchase_category: '',
      secondary_code: '',
      promo_code: '',
      promo_popup: false,
      customer_email: '',
      sidebarActivated: false,
      proceed_transaction: false,
      billing_info: {},
      publishableKey: process.env.VUE_APP_STRIPE_KEY,
      corporateItems: [
      ],
      subscriptionDetails: [],
      subscribedPackages: [],
      successURL: null,
      cancelURL: null,
      blur_check: 'blur',
      job_link: '',
      not_fullname: '',
      not_email: '',
      not_position: '',
      not_industry: '',
      event_id: '',
      fullname: '',
      email: '',
      resume_uploaded: false,
      show_inside: false,
      popupActive: false,
      image_url: 'default',
      event_exist: false,
      action: 'insert',
      current_item: {},
      popupReg: false,
      item_id: '',
      job: {},
      companies: [],
      registered: [],
      companiesData: [],
      industriesData: [],
      select_tags: [],
      select_name: '',
      all_positions: [],
      selected_status: '',
      selected_monitored_positions: [],
      selected_monitored_industries: [],
      selected_industries: [],
      selected_positions: [],
      selected_company: '',
      selected_active_monitoring_company: {},
      current_user: '',
      position: '',
      description: '',
      job_count: 0,
      default_count: 50,
      pagination: {
        skip: 0,
        limit: 10,
      },
      selectedRows: [],
      serverParams: {
        columnFilters: {},
        sort: {
          company_name: 'asc',
        },
        page: 1,
        perPage: 10,
        totalRows: 0,
      },
      recruitment_statuses: [
        { text: 'All recruitment status', value: 0 },
        { text: '✔️Active', value: 2 },
        { text: '❌Inactive', value: 1 },
      ],
      // industries: [
      //   {
      //     text: 'Accommodation and food service activities',
      //     value: 'Accommodation and food service activities',
      //   },
      //   {
      //     text: 'Administrative and support service activities',
      //     value: 'Administrative and support service activities',
      //   },
      //   { text: 'Agriculture and fishing', value: 'Agriculture and fishing' },
      //   {
      //     text: 'Arts, entertainment and recreation',
      //     value: 'Arts, entertainment and recreation',
      //   },
      //   { text: 'Construction', value: 'Construction' },
      //   { text: 'Education', value: 'Education' },
      //   {
      //     text: 'Electricity,gas,steam and air-conditioning supply',
      //     value: 'Electricity,gas,steam and air-conditioning supply',
      //   },
      //   {
      //     text: 'Financial and insurance activities',
      //     value: 'Financial and insurance activities',
      //   },
      //   {
      //     text: 'Health and social services',
      //     value: 'Health and social services',
      //   },
      //   {
      //     text: 'Information and communications',
      //     value: 'Information and communications',
      //   },
      //   { text: 'Manufacturing', value: 'Manufacturing' },
      //   { text: 'Mining and quarrying', value: 'Mining and quarrying' },
      //   {
      //     text: 'Other service activities',
      //     value: 'Other service activities',
      //   },
      //   {
      //     text: 'Professional, scientific and technical activities',
      //     value: 'Professional, scientific and technical activities',
      //   },
      //   {
      //     text: 'Public administration and defence',
      //     value: 'Public administration and defence',
      //   },
      //   { text: 'Real estate activities', value: 'Real estate activities' },
      //   {
      //     text: 'Transportation and storage',
      //     value: 'Transportation and storage',
      //   },
      //   {
      //     text: 'Water supply;sewerage waste management and remediation activities',
      //     value:
      //       'Water supply;sewerage waste management and remediation activities',
      //   },
      //   {
      //     text: 'Wholesale and retail trade',
      //     value: 'Wholesale and retail trade',
      //   },
      // ],
      industryOptions: [],
      positionOptions: [],
      dynamicIndustryOptions: [],
      dynamicPositionOptions: [],
      industryOnlyOptions: [],
      positionOnlyOptions: [],

      subscribedIndustries: [],
      subscribedPositions: [],

      dates: [],
      popup_payment: false,
      account_status: { corporate_directory: { active: false } },
      payment_id: '',
      instance: null,

      popup_corporate_details: {
        state: false,
        data: {},
      },
      popup_active_monitoring_list: {
        state: false,
        data: [],
        fields: [],
        serverParams: {
          currentPage: 1,
          perPage: 5,
          totalRows: 1,
        },
      },

      popup_active_industries_monitoring_list: {
        state: false,
        data: [],
        fields: [],
        serverParams: {
          currentPage: 1,
          perPage: 5,
          totalRows: 1,
        },
      },

      popup_active_positions_monitoring_list: {
        state: false,
        data: [],
        fields: [],
        serverParams: {
          currentPage: 1,
          perPage: 5,
          totalRows: 1,
        },
      },

    }
  },
  computed: {
    eventsGroups() {
      return Array.from(Array(Math.ceil(this.companiesData.length / 4)).keys())
    },
  },
  watch: {
    selected_status: {
      handler(val, oldVal) {
        this.updateFilters('recruitment_status', val.value)
        // this.filterAgencies()
      },
    },
    selected_industries: {
      handler(val, oldVal) {
        this.getDirectory()
        // this.updateFilters('selected_industries', val)
        // this.filterAgencies()
        // this.filter_events();
      },
    },

    selected_positions: {
      handler(val, oldVal) {
        this.getDirectory()
        // this.updateFilters('selected_industries', val)
        // this.filterAgencies()
        // this.filter_events();
      },
    },
    selected_company: {
      handler(val, oldVal) {
        this.filterAgencies()
        // this.filter_events();
      },
    },
    subscribedIndustries: {
      handler(items) {
        this.dynamicIndustryOptions = this.industryOptions.filter(option => !items.map(val => val.industry_name).includes(option.text))
      },
    },
    subscribedPositions: {
      handler(items) {
        this.dynamicPositionOptions = this.positionOptions.filter(option => !items.map(val => val.name).includes(option.text))
      },
    },
    'popup_active_monitoring_list.state': function () {
      this.getListOfActiveMonitoring()
    },
    'popup_active_industries_monitoring_list.state': function () {
      this.getListOfActiveIndustriesMonitoring()
    },
    'popup_active_positions_monitoring_list.state': function () {
      this.getListOfActivePositionsMonitoring()
    },

    'popup_active_industries_monitoring_list.data': {
      handler(item) {
        this.subscribedIndustries = item
        // this.selected_monitored_industries = item.map(val => val.industry_name)
      },
    },
    'popup_active_positions_monitoring_list.data': {
      handler(item) {
        this.subscribedPositions = item
      },
    },

    deep: true,
  },

  mounted() {
    try {
      const next = this.$children[0].$refs.btnclose
      next.$el.addEventListener('click', this.onClickCloseButton, false)
    } catch (error) {}

    this.getIndustryOptions()
    this.getPositionOptions()
  },

  created() {
    // const { token } = this.$store.state.auth.ActiveUser || {}
    this.email_address = this.$store.state.auth.ActiveUser.email
    // this.$http.defaults.headers.common["Authorization"] = `Token ${token}`;
    try {
      this.resume_uploaded = this.$store.state.auth.ActiveUser.resume_upload
    } catch (error) {}

    this.getDirectory()

    EventBus.$on('cartItemRemoved', record => {
      this.toggleCompanyBasketIcon(record)
    })

    EventBus.$on('cartCheckout', () => {
      this.getBillingInfo()
    })

    this.getSubscriptionDetails()
    const queryParams = this.$router.currentRoute.query
    if (queryParams) {
      const { payment } = queryParams
      if (payment === 'success') {
        this.$toastSuccess('Corporate Directory Notification', 'Payment has been received successfully. You can now enjoy access to the section you paid for.')
      } else if (payment === 'cancel') {
        this.$toastDanger('Corporate Directory Notification', 'You have cancelled the payment operation successfully.')
      }
    }
  },
  destroyed() {
    EventBus.$off('cartItemRemoved')
    EventBus.$off('cartCheckout')
  },
  methods: {
    findTextForValue(listOfItems, valueToFind) {
      for (let i = 0; i < listOfItems.length; i++) {
        if (listOfItems[i].value === valueToFind) {
          return listOfItems[i].text
        }
      }
      return null // Return null if value is not found
    },
    addToCart() {

    },
    subscribeNow() {
      this.subText = 'Subscribe Now'
      const productName = this.purchase_info.default_product.name
      if (productName === 'Monitor Company Industry') {
        this.selected_products = this.selected_monitored_industries.map(item => ({
          name: `${productName}: ${item}`,
          description: `Add ${item} to industries watchlist`,
          amount: this.purchase_info.default_product.amount.toString(),
          period: 'month',
        }))
      } else if (productName === 'Monitor Position') {
        this.selected_products = this.selected_monitored_positions.map(item => ({
          name: `${productName}: ${item}`,
          description: `Add ${item} to positions watchlist`,
          amount: this.purchase_info.default_product.amount.toString(),
          period: 'month',
        }))
      }

      this.subText = 'Loading...'
      this.getBillingInfo()
    },
    getSubscriptionDetails() {
      this.$http
        .get('/api/subscription-info')
        .then(response => {
          const { success, result } = response.data
          if (success) {
            this.subscriptionDetails = result.subscribed_packages
            this.subscribedPackages = result.stripped_subscribed_packages
          }
        })
        .catch(() => {})
    },
    toggleMonitoringList(type, bypass = false) {
      if (type === 'companies') {
        if (this.subscribedPackages.includes('Monitor Company Industry') && bypass === false) {
          this.popup_active_monitoring_list.state = !this.popup_active_monitoring_list.state
        } else {
          this.purchase_info = {
            default_product: {
              name: 'Monitor Company Industry',
              description: 'you will gain the ability to monitor an unlimited number of companies across any industries of your choosing. This comprehensive package allows you to keep a close watch on the companies and sectors most relevant to your interests and needs.',
              price: 'monthly cost of S$35 per industry', // Assuming this is where the price will be displayed
              amount: 35,
            },
          }

          this.purchaseConfirmationPopup = true
        }
      } else if (type === 'positions') {
        // this.popup_active_positions_monitoring_list.state = false
        if (this.subscribedPackages.includes('Monitor Position') && bypass === false) {
          this.popup_active_positions_monitoring_list.state = !this.popup_active_positions_monitoring_list.state
        } else {
          this.purchase_info = {
            default_product: {
              name: 'Monitor Position',
              description: 'you will gain the ability to monitor an unlimited number of positions across any companies. This comprehensive package allows you to keep a close watch on the positions relevant to your interests and needs.',
              price: 'monthly cost of S$50 per position', // Assuming this is where the price will be displayed
              amount: 50,
            },
          }

          this.purchaseConfirmationPopup = true
        }
      } else if (type === 'industries') {
        // this.popup_active_industries_monitoring_list.state = false
        if (this.subscribedPackages.includes('Monitor Company Industry') && bypass === false) {
          this.popup_active_industries_monitoring_list.state = !this.popup_active_industries_monitoring_list.state
        } else {
          this.purchase_info = {
            default_product: {
              name: 'Monitor Company Industry',
              description: 'you will gain the ability to monitor an unlimited number of companies across any industries of your choosing. This comprehensive package allows you to keep a close watch on the companies and sectors most relevant to your interests and needs.',
              price: 'monthly cost of S$35 per industry', // Assuming this is where the price will be displayed
              amount: 35,
            },
          }

          this.purchaseConfirmationPopup = true
        }
      }
    },
    checkSubscription(item) {
      const currentList = this.popup_active_monitoring_list
      this.popup_corporate_details.state = true
      this.popup_corporate_details.data = item
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
      this.getDirectory()
    },
    onPageChange(params) {
      const prevSelectedROws = this.selectedRows
      this.updateParams({ page: params.currentPage })
      this.updateParams({ selectedRows: prevSelectedROws })
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
    },
    onSortChange(params) {
      if (params[0]) {
        const fieldName = params[0].field
        const sortDetails = { }
        sortDetails[fieldName] = params[0].type
        this.updateParams({ sort: sortDetails })
      }
    },
    onColumnFilter(params) {
      this.serverParams.page = 1
      this.updateParams(params)
    },
    onSelectionChange(params) {
      this.selectedRows = params.selectedRows
    },
    updateFilters(column, value) {
      const currentFilters = this.serverParams.columnFilters
      if (this.$isNotEmpty(value)) {
        currentFilters[column] = value
      } else {
        delete currentFilters[column]
      }
      this.updateParams({ columnFilters: currentFilters })
    },

    checkDescription(description) {
      return description ? `(${description})` : ''
    },
    validatePromo() {
      if (this.promo_code.length > 2) {
        this.$http
          .get(`/api/promo-code/${this.promo_code}/${this.secondary_code}`)
          .then(response => {
            if (response.data.success) {
              this.promo_popup = false
              this.promo_code = ''
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Promo Code Alert',
                    icon: 'CheckCircleIcon',
                    text: 'Your subscription has been activated successfully',
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )

              window.location.reload()
            } else {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Promo Code Alert',
                    icon: 'AlertCircleIcon',
                    text: 'The promo code you have entered is either invalid or has already expired.',
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
          })
          .catch(error => {})
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Promo Code Notification',
              icon: 'AlertCircleIcon',
              text: 'A valid promo code is required before validation.',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    skipPromo() {
      const section = this.purchase_category
      this.promo_popup = false

      this.$refs.checkoutRef.redirectToCheckout()
    },
    toggleDataSidebar() {
      this.sidebarActivated = false
      this.purchaseConfirmationPopup = true
      this.subText = 'Subscribe Now'
      this.subscribeNow()
    },
    createSubscriptionCheckout() {
      this.$http
        .post('/api/products-stripe', {
          data: this.selected_products,
        })
        .then(response => {
          const { result, success } = response.data

          if (success) {
            this.checkoutUrl = result.checkout_url
            // this.isLoading = false

            this.subText = 'Redirecting to stripe...'
            window.location.href = this.checkoutUrl
          } else {
            this.$toastDanger('Corporate Directory Notification', 'Payment generation failed, please try again later')
          }
        })
        .catch(error => {
          // this.isLoading = false
        })
    },

    getBillingInfo() {
      this.$http
        .get('/api/billing-info')
        .then(response => {
          if (response.data.success) {
            const results = response.data.output

            this.billing_info = results
            const neededKeys = [
              'phone_number',
              'email_address',
              'address_1',
              'first_name',
              'last_name',
              'company_id',
            ]
            if (
              neededKeys.every(key => Object.keys(this.billing_info).includes(key))
            ) {
              this.proceed_transaction = true
            } else {
              this.proceed_transaction = false
            }

            if (this.proceed_transaction) {
              this.sidebarActivated = false
              // this.$refs.checkoutRef.redirectToCheckout()
              this.createSubscriptionCheckout()
            } else {
              this.$toastDanger('Billing Notification', 'Please update your billing information before subscribing')

              this.sidebarActivated = true
            }
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Billing Notification',
                  icon: 'AlertCircleIcon',
                  text: 'Please update your billing information before subscribing',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )

            this.sidebarActivated = true
            this.purchaseConfirmationPopup = false
            this.subText = 'Subscribe Now'
          }
        })
        .catch(error => {})
    },
    subscribe(section) {
      this.getBillingInfo()
      this.purchase_category = section
      if (section === 'corporate_directory') {
        this.secondary_code = 300
        this.successURL = `https://dashboard.headhuntershq.com/payment-success/${this.payment_id}/300`
      }
      if (this.proceed_transaction) {
        this.promo_popup = true
      } else {

      }
    },
    generate_payment_id() {
      this.$http
        .get('/api/payment-id/300')
        .then(response => {
          if (response.data.success) {
            this.payment_id = response.data.payment_id
          }
        })
        .catch(error => {})
    },

    handleCompanyInput: debounce(function (text) {
      // if (text.length === 0) {
      //   this.selected_company = ''
      //   this.filterAgencies()
      // }
      // this.getEmployers(text)
      if (this.$isNotEmpty(text)) {
        this.$set(this, 'selected_company', text.company_name)
        this.updateFilters('company_name', this.selected_company)
      } else {
        this.updateFilters('company_name', null)
      }
    }, 1000),

    // handleCompanyInput:debounce(() => {
    //
    //   }, 1000),

    selectActiveMonitoringCompany(selected) {
      this.selected_active_monitoring_company = selected
    },

    handleIndustryInput(text) {
      return text
    },
    getEmployers(query) {
      this.companies = []

      this.$http
        .get(`/api/company-search/${query}`)
        .then(response => {
          if (response.data.success) {
            const records = response.data.data
            records.map(item => {
              if (!this.companies.includes(item.text)) {
                this.companies.push(item.text)
              }
            })
          }
        })
        .catch(error => {})
    },
    check_empty(value) {
      if (value) {
        if (value.length > 4) {
          return true
        }
        return false
      }
      return true
    },
    send_response() {
      if (
        this.check_empty(this.not_fullname)
        && this.check_empty(this.not_email)
        && this.check_empty(this.not_position)
      ) {
        const all_data = {
          fullname: this.not_fullname,
          email: this.not_email,
          position: this.not_position,
          industry: this.not_industry,
        }
        this.$http
          .post('/api/response', { data: all_data })
          .then(response => {
            const { success } = response.data
            if (success) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Response Sent',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )

              this.not_position = ''
              this.not_fullname = ''
              this.not_email = ''
              this.not_industry = ''
            } else {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Response Failed',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
          })
          .catch(error => {})
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Response Failed',
              icon: 'AlertCircleIcon',
              text: 'Please fill all form values correctly.',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },

    viewMore() {
      this.default_count *= 2
      this.getDirectory()
    },

    filterAgencies() {
      this.getDirectory()
    },
    trimMyString(string) {
      const start = 0
      const maxLength = 200
      // Note - `start` is if I want to start after some point of the string
      if (string.length > maxLength) {
        const trimmedString = string.substr(start, maxLength)
        return `${trimmedString.substr(
          start,
          Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')),
        )} ......`
      }
      return string
    },
    filter_events(data) {
      // let selected_tags = [];
      // for (let data of this.categories) {
      //   for (let categ of this.select_tags) {
      //     if (data.value === categ) {
      //       selected_tags.push(data.text);
      //     }
      //   }
      // }

      // let selected_companies = [];
      // for (let data of this.companies) {
      //   for (let categ of this.select_company) {
      //     if (data.value === categ) {
      //       selected_companies.push(data.text);
      //     }
      //   }
      // }

      let new_events = false
      if (data.ea_name.includes(this.select_name)) {
        selected_date = data.text
        new_events = true
      }

      // let new_events = false;

      // let comp =
      //   selected_companies.length > 0
      //     ? selected_companies.includes(data.company)
      //     : true;
      // let tags =
      //   selected_tags.length > 0
      //     ? selected_tags.some((v) => data.tags.includes(v))
      //     : true;
      // let dates = data.position
      //   .toLowerCase()
      //   .includes(this.select_position.toLowerCase());

      // if (comp && tags && dates) {
      //   new_events = true;
      // }

      return new_events
      // this.companiesData = new_events;
    },

    selectOne(jobid, all_data) {
      let active_job = {}
      for (const item of all_data) {
        if (item._id === jobid) {
          active_job = item
        }
      }
      if (Object.keys(active_job).length !== 0) {
        this.viewMore(active_job)
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Job Alert',
              icon: 'AlertCircleIcon',
              text: "It seems this job position has been filled or doesn't exists.",
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },

    editEvent(item) {
      this.action = 'update'
      this.item_id = item._id
      this.position = item.position
      this.description = item.description
      //   this.select_date = item.date;
      //   this.select_tags = item.tags;
      this.image_url = item.image_url
      this.popupActive = true
    },

    getDirectory() {
      this.isLoading = true
      this.companiesData = []
      this.events_part_data = []

      let query = ''
      if (!this.exportingCSV) { // if it is not for csv will append pagination details else will not paginate and will retrieve all data
        query += `${this.$attachQuerySymbol(query)}pagination=${JSON.stringify(this.pagination)}`
      }
      query = `?params=${JSON.stringify(this.serverParams)}`

      this.$http
        .post(`/api/corporate-companies${query}`, {
          form_data: {
            selected_industries: this.selected_industries, // * list could get too long to be put into query
            selected_positions: this.selected_positions,
          },
        })
        .then(response => {
          const { params, output } = response.data
          this.companiesData = output
          this.serverParams = params
          this.isLoading = false
          // if (response.data.success) {
          //   this.account_status = response.data.account_status
          //   if (this.account_status.corporate_directory.active) {
          //     this.events_part_data = response.data.message
          //     this.blur_check = ''
          //     this.popup_payment = false
          //   } else {
          //     this.blur_check = 'blur'
          //     this.popup_payment = true
          //   }

          //   // Array.prototype.subarray = function (start, end) {
          //   //   if (!end) {
          //   //     end = -1;
          //   //   }
          //   //   return this.slice(start, this.length + 1 - end * -1);
          //   // };
          //   // this.companiesData = this.events_part_data.slice(
          //   //   0,
          //   //   this.default_count + 1
          //   // );
          //   this.companiesData = this.events_part_data
          //   this.isLoading = false
          // } else {
          //   this.event_exist = false
          //   this.isLoading = false
          // }
        })
        .catch(error => {
          this.isLoading = false
        })
    },
    extract_values() {
      const selected_tags = []
      for (const data of this.categories) {
        for (const categ of this.select_tags) {
          if (data.value === categ) {
            selected_tags.push(data.text)
          }
        }
      }
      let selected_date = ''
      for (const data of this.dates) {
        if (data.value === this.select_date) {
          selected_date = data.text
        }
      }

      const all_data = {
        position: this.position,
        description: this.description,
        date: selected_date,
        tags: selected_tags,
        image_url: this.image_url,
      }
      return all_data
    },

    onClickCloseButton() {
      this.$emit('closePopup')
    },

    updateFiles(input) {
      if (this.email) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Resume Upload',
              icon: 'AlertCircleIcon',
              text: 'Resume upload in progress.',
              variant: 'warning',
            },
          },
          { timeout: this.$longestTimeout },
        )
        if (input.target.files && input.target.files[0]) {
          const reader = new FileReader()
          reader.onload = e => {}
          const fd = new FormData()
          fd.append('file', input.target.files[0])
          delete this.$http.defaults.headers.common.Authorization
          this.$http
            .post(`/api/upload-resume/${this.email}`, fd)
            .then(response => {
              this.resume_uploaded = true
              // let image_url = response.data.data.display_url;
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Resume Upload',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            })
            .catch(error => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Resume Upload',
                    icon: 'AlertCircleIcon',
                    text: 'Resume upload failed. Try again later.',
                    variant: 'warning',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            })
          reader.readAsDataURL(input.target.files[0])
        }
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Resume Upload',
              icon: 'AlertCircleIcon',
              text: 'Please fill the form above to proceed.',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },

    toggleCompanyBasketIcon(record) {
      const itemIndex = this.companiesData.findIndex(p => p.id === record.id)
      if (this.companiesData[itemIndex].shopping_cart) {
        this.companiesData[itemIndex].shopping_cart = false
      } else {
        this.companiesData[itemIndex].shopping_cart = true
      }
    },
    toggleCompanyShoppingCart(record, index = null) {
      const { cartProducts } = this.$store.state.appEcommerce
      const productId = record.id

      // Check if the product ID already exists in cartProducts
      const existingIndex = cartProducts.findIndex(product => product.id === productId)

      if (existingIndex !== -1) {
        // Product already exists, remove it from the array
        cartProducts.splice(existingIndex, 1)
      } else {
        // Product doesn't exist, push it to the array
        const product = {
          id: productId,
          name: `Monitor ${record.company_name} for 1 Month`,
          price: 30,
          cart_type: record.cart_type,
          price_id: process.env.VUE_APP_MONITOR_COMPANY_PRICE_ID,
        }

        cartProducts.push(product)
      }
      this.toggleCompanyBasketIcon(record)
      this.$store.commit('appEcommerce/SET_CART_PRODUCTS', cartProducts)
    },

    toggleActiveMonitoring(record, index = null) {
      if (this.subscribedPackages.includes('Monitor Company Industry')) {
        this.$http
          .post('/api/corporate-directory/active-monitoring', {
            form_data: {
              company_id: record.id,
            },
          })
          .then(response => {
            const { success, output, message } = response.data
            if (success) {
              if (this.$isNotEmpty(index)) {
                this.companiesData[index].user_active_monitor_state = !this.companiesData[index].user_active_monitor_state
              } else {
                // this.getDirectory()
                this.getListOfActiveMonitoring()
              }
            }
          })
          .catch(error => {
          //
          })
      } else {
        this.purchase_info = {
          default_product: {
            name: 'Monitor Company Industry',
            description: 'you will gain the ability to monitor an unlimited number of companies across any industries of your choosing. This comprehensive package allows you to keep a close watch on the companies and sectors most relevant to your interests and needs.',
            price: 'monthly cost of S$35 per industry', // Assuming this is where the price will be displayed
            amount: 35,
          },
        }

        this.purchaseConfirmationPopup = true
        // this.$toastDanger('Corporate Directory Notification', `You have to subscribe by clicking the shopping cart button to monitor ${record.company_name}`)
      }
    },

    getListOfActiveMonitoring() {
      const params = `?params=${JSON.stringify(this.popup_active_monitoring_list.serverParams)}`

      this.$http
        .get(`/api/corporate-directory/active-monitoring-list${params}`)
        .then(response => {
          const {
            success, output, message, params,
          } = response.data
          if (success) {
            this.popup_active_monitoring_list.data = output
            this.popup_active_monitoring_list.fields = [
              'company_name', 'uen_no', 'actions',
            ]
            this.popup_active_monitoring_list.serverParams = params
          }
        })
        .catch(() => {})
    },

    getListOfActiveIndustriesMonitoring() {
      const params = `?params=${JSON.stringify(this.popup_active_industries_monitoring_list.serverParams)}`

      this.$http
        .get(`/api/corporate-directory/active-industries-monitoring-list${params}`)
        .then(response => {
          const {
            success, output, message, params,
          } = response.data

          if (success) {
            this.popup_active_industries_monitoring_list.data = output
            this.popup_active_industries_monitoring_list.fields = [
              'industry_name', 'actions',
            ]
            this.popup_active_industries_monitoring_list.serverParams = params
          }
        })
        .catch(() => {})
    },

    toggleActiveMonitoringIndustry(industry, index = null) {
      let industry_ids = []

      if (this.$isEmpty(industry)) {
        industry_ids = this.selected_monitored_industries
      } else {
        industry_ids.push([industry.industry_code])
      }

      this.$http
        .post('/api/corporate-directory/active-industries-monitoring', {
          form_data: {
            industry_ids,
          },
        })
        .then(response => {
          const { success, output, message } = response.data
          if (success) {
            if (this.$isNotEmpty(index)) {
              this.industriesData[index].user_active_monitor_state = !this.industriesData[index].user_active_monitor_state
            } else {
              // this.getIndustries()
              this.getListOfActiveIndustriesMonitoring()
            }
          }
        })
        .catch(error => {
          //
        })
    },

    toggleActiveMonitoringPosition(position, index = null) {
      let position_ids = []

      if (this.$isEmpty(position)) {
        position_ids = this.selected_monitored_positions
      } else {
        position_ids.push([position.ssoc_code])
      }

      this.$http
        .post('/api/corporate-directory/active-positions-monitoring', {
          form_data: {
            position_ids,
          },
        })
        .then(response => {
          const { success, output, message } = response.data
          if (success) {
            if (this.$isNotEmpty(index)) {
              this.positionsData[index].user_active_monitor_state = !this.positionsData[index].user_active_monitor_state
            } else {
              // this.getPositions()
              this.getListOfActivePositionsMonitoring()
            }
          }
        })
        .catch(error => {
          //
        })
    },

    getListOfActivePositionsMonitoring() {
      const params = `?params=${JSON.stringify(this.popup_active_positions_monitoring_list.serverParams)}`

      this.$http
        .get(`/api/corporate-directory/active-positions-monitoring-list${params}`)
        .then(response => {
          const {
            success, output, message, params,
          } = response.data
          if (success) {
            this.popup_active_positions_monitoring_list.data = output
            this.popup_active_positions_monitoring_list.fields = [
              'position_name', 'actions',
            ]
            this.popup_active_positions_monitoring_list.serverParams = params
          }
        })
        .catch(() => {})
    },

    getIndustryOptions() {
      this.$http
        .get('/api/get-industry-options')
        .then(response => {
          const { success, output, message } = response.data
          if (success) {
            this.industryOptions = output
            this.industryOnlyOptions = output.map(item => item.text)
            this.dynamicIndustryOptions = output.filter(item => !this.selected_monitored_industries.includes(item.text))
          }
        })
        .catch(() => {})
    },

    getPositionOptions() {
      this.$http
        .get('/api/get-position-options')
        .then(response => {
          const { success, output, message } = response.data
          if (success) {
            this.positionOptions = output
            this.positionOnlyOptions = output.map(item => item.text)
            this.dynamicPositionOptions = output.filter(item => !this.selected_monitored_positions.includes(item.text))
          }
        })
        .catch(() => {})
    },

  },
}
</script>
<style lang="scss">
.blur {
  filter: blur(16px);
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
